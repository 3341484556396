



























































































































































































































































































































































































































































































































































import {
  Component, Prop, Vue, Ref, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import AdunitScheduler from '@/models/AdunitScheduler'
import Widget from '@/components/Widget/Widget.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import CheckboxInput from '@/components/CheckboxInput/index.vue'
import SpinbuttonInput from '@/components/SpinbuttonInput/index.vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import rrulePlugin from '@fullcalendar/rrule'
import IconAction from '@/components/IconAction/IconAction.vue'
import Schedule from '@/models/interface/Schedule'
import ScheduleOverwrite from '@/models/interface/ScheduleOverwrite'
import moment from 'moment'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import { clone as _clone } from 'lodash'
// @ts-ignore
import LiquorTree from 'liquor-tree'
import WebMessage from '@/models/WebMessage'
import Inventory from '@/models/Inventory'
import LiveEvent from '@/models/LiveEvent'
import options from './options'

Vue.use(LiquorTree)

@Component({
  components: {
    Widget,
    FormInput,
    SelectPicker,
    CheckboxInput,
    FullCalendar,
    IconAction,
    DatePicker,
    SpinbuttonInput,
  },
})
export default class AdunitSchedulerEdit extends ViewModel {
  @Ref() readonly calendar: any

  @Ref() readonly treeview: any

  @Ref() readonly treeviewExclude: any

  @Prop({
    default: '',
  })
  public id!: string

  @Prop({
    default: '',
  })
  public from!: string

  @Prop({
    default: 'full',
  })
  public view_mode!: string

  public adunit_filter: string = ''

  public adunit_exclude_filter: string = ''

  public fields: Array<any> = [
    {
      key: 'form',
      label: 'Form',
      show: true,
    },
    {
      key: 'calendar',
      label: 'Calendar',
      show: true,
    },
  ]

  public adunits = []

  public tree_options = {
    checkbox: true,
    checkOnSelect: false,
    autoCheckChildren: false,
  }

  public busy = true

  public show_panel = false

  public modal = {
    overwrite: false,
  }

  public scheduler: AdunitScheduler = new AdunitScheduler()

  public event: Schedule = new Schedule('weekly')

  public overwrite: ScheduleOverwrite = new ScheduleOverwrite()

  public get show_calendar(): boolean {
    return this.fields.find(field => field.key === 'calendar').show
  }

  public get show_form(): boolean {
    return this.fields.find(field => field.key === 'form').show
  }

  public get type_options() {
    return options.type_options
  }

  public get weekdays_options() {
    return options.weekdays_options
  }

  public get monthdays_options() {
    return options.monthdays_options
  }

  public get status_options() {
    return options.status_options
  }

  public get automation_options() {
    return options.automation_options
  }

  public get automated_mode_options() {
    return options.automated_mode_options
  }

  public get overwrite_type_options() {
    return options.overwrite_type_options
  }

  public get network_options() {
    return LiveEvent.getNetworkOptions(this.event.league_id)
  }

  public get event_options() {
    return LiveEvent.getOptionsByLeague(this.event.league_id, this.event.network_ids)
  }

  public get today() {
    return moment().format('YYYY-MM-DD')
  }

  public get title(): string {
    return this.id ? 'Edit Scheduler' : 'Create Scheduler'
  }

  public get calendarOptions() {
    return {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, rrulePlugin],
      initialView: 'timeGridWeek',
      nowIndicator: false,
      events: this.scheduler.calendarEvents,
      headerToolbar: {
        start: 'prev,next, title',
        end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
      },
      bootstrapFontAwesome: {
        close: ' la la-times',
        prev: ' la la-angle-left',
        next: ' la la-angle-right',
        prevYear: ' la la-angle-double-left',
        nextYear: ' la la-angle-double-right',
      },
      buttonText: {
        today: 'Today',
        dayGridMonth: 'Month',
        timeGridWeek: 'Week',
        timeGridDay: 'Day',
        listMonth: 'List',
      },
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      weekends: true,
      allDaySlot: false,
      select: this.handleDateSelect,
      eventClick: this.handleEventClick,
      eventsSet: this.handleEvents,
      eventChange: this.handleEventChange,
    }
  }

  public mounted() {
    // Fectch global Scheduler
    const global_scheduler = AdunitScheduler.module.data.find(s => s.is_global)
    if (!global_scheduler) {
      AdunitScheduler.get('global')
    }
  }

  public created() {
    this.busy = true
    if (LiveEvent.module.data.length === 0) {
      LiveEvent.fetchAllValidEvents().then(r => {
        this.init()
      })
    } else {
      this.init()
    }
  }

  public init() {
    const { query } = this.$route
    Inventory.module.getTree().then(tree => {
      this.adunits = tree
      if (this.id) {
        AdunitScheduler.get(this.id).then(scheduler => {
          if (scheduler instanceof AdunitScheduler) {
            this.scheduler = scheduler
            this.busy = false
            if (!this.scheduler.is_global) {
              setTimeout(() => {
                this.treeview.findAll((n: any) => this.scheduler.adunit_ids.includes(n.id)).check()
                if (this.scheduler.apply_to_children) {
                  this.treeviewExclude
                    .findAll((n: any) => this.scheduler.exclude_adunit_ids.includes(n.id))
                    .check()
                }
              }, 500)
            }
          }
        })
      } else if (query.from && typeof query.from === 'string') {
        AdunitScheduler.get(query.from).then(scheduler => {
          if (scheduler instanceof AdunitScheduler) {
            this.scheduler = _clone(scheduler)
            this.scheduler.id = ''
            this.scheduler.is_global = false
            this.busy = false
            setTimeout(() => {
              this.treeview.findAll((n: any) => this.scheduler.adunit_ids.includes(n.id)).check()

              if (this.scheduler.apply_to_children) {
                this.treeviewExclude
                  .findAll((n: any) => this.scheduler.exclude_adunit_ids.includes(n.id))
                  .check()
              }
            }, 500)
          }
        })
      } else {
        this.busy = false
      }
    })
  }

  public cancel() {
    if (this.view_mode == 'sidebar') {
      this.$emit('update:show_sidebar', false)
    } else {
      this.$router.push({ name: 'Schedulers' })
    }
  }

  public addEvent() {
    this.event = new Schedule('once')
    this.show_panel = true
  }

  public cloneEvent(id: number) {
    const event = this.scheduler.events.find(x => x.id === id)
    if (event) {
      this.event = _clone(event)
      this.event.id = 0
      this.show_panel = true
    }
  }

  public editEvent(id: number) {
    const event = this.scheduler.events.find(x => x.id === id)
    if (event) {
      this.event = new Schedule('once')
      this.show_panel = true
      // Workaround to update Datepicker component
      setTimeout(() => {
        this.event = Schedule.toObject(_clone(event))
      }, 500)
    }
  }

  public saveEvent() {
    const error = this.scheduler.validateEvent(this.event)
    if (error === true) {
      if (this.event.id) {
        this.scheduler.updateEvent(this.event)
      } else {
        this.scheduler.addEvent(this.event)
      }
      this.show_panel = false
    } else if (typeof error === 'string') {
      WebMessage.error(error)
    }
  }

  public cancelEvent() {
    this.show_panel = false
  }

  public removeEvent(id: number) {
    this.scheduler.removeEvent(id)
  }

  public addOverwrite() {
    this.overwrite = new ScheduleOverwrite()
    this.modal.overwrite = true
  }

  public cancelOverwrite() {
    this.modal.overwrite = false
  }

  public editOverwrite(id: number) {
    const overwrite = this.event.overwrites.find(x => x.id === id)
    if (overwrite) {
      this.overwrite = _clone(overwrite)
      this.modal.overwrite = true
    }
  }

  public saveOverwrite() {
    if (this.overwrite.id) {
      this.event.updateOverwrite(this.overwrite)
    } else {
      this.event.addOverwrite(this.overwrite)
    }
    this.modal.overwrite = false
  }

  public removeOverwrite(id: number) {
    this.event.removeOverwrite(id)
  }

  public onSubmit() {
    if (!this.scheduler.is_global) {
      this.scheduler.adunit_ids = this.treeview.checked().map((x: any) => x.id)

      if (this.scheduler.apply_to_children) {
        this.scheduler.exclude_adunit_ids = this.treeviewExclude.checked().map((x: any) => x.id)
      } else {
        this.scheduler.exclude_adunit_ids = []
      }
    }

    if (!this.scheduler.is_global && this.scheduler.adunit_ids.length === 0) {
      WebMessage.error('Please select at least one adunit')
      return
    }

    if (!this.scheduler.is_global && this.scheduler.events.length === 0) {
      WebMessage.error('Please add at least one event')
      return
    }

    this.loading = true
    this.scheduler
      .save()
      .then(response => {
        if (response.status == 200) {
          this.$router.push({ name: 'Schedulers' })
        }
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }

  public handleEventChange(e: any) {
    const id = Number(e.event.id)
    const event = this.scheduler.events.find(x => x.id === id)
    if (event && !e.event.allDay) {
      if (['weekly', 'monthly'].includes(e.event.extendedProps.type)) {
        event.start_time = moment(e.event.start).format('HH:mm:ss')
        event.end_time = moment(e.event.end).format('HH:mm:ss')
      } else if (e.event.extendedProps.type === 'once') {
        event.start_at = moment(e.event.start).format('YYYY-MM-DD HH:mm:ss')
        event.end_at = moment(e.event.end).format('YYYY-MM-DD HH:mm:ss')
      }
      this.scheduler.updateEvent(event)
    } else if (event) {
      this.scheduler.updateEvent(event)
    }
  }

  public handleDateSelect(selectInfo: any) {
    if (!selectInfo.allDay) {
      this.event = new Schedule(
        'once',
        moment(selectInfo.start).format('YYYY-MM-DD HH:mm:ss'),
        moment(selectInfo.end).format('YYYY-MM-DD HH:mm:ss'),
      )
      this.show_panel = true
    }
    const calendarApi = selectInfo.view.calendar
    calendarApi.unselect() // clear date selection
  }

  public handleEventClick(e: any) {
    const id = Number(e.event.id)
    this.editEvent(id)
  }

  public handleEvents(events: any) {
    // this.currentEvents = events;
  }
}
