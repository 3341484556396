export default {
  automation_options: [
    {
      value: 'mlb',
      name: 'MLB',
    },
    {
      value: 'nba',
      name: 'NBA',
    },
    {
      value: 'nfl',
      name: 'NFL',
    },
    {
      value: 'nhl',
      name: 'NHL',
    },
    {
      value: 'ncaa_football',
      name: 'NCAA Football',
    },
    /* {
      value: "ncaa_basketball",
      name: "NCAA Basketball",
    }, */
  ],
  overwrite_type_options: [
    {
      value: 'cancel',
      name: 'Cancel',
    },
  ],
  automated_mode_options: [
    {
      value: 'game_duration',
      name: 'Only Active during live games',
    },
    {
      value: 'all_day',
      name: 'Active all day, but only on game days',
    },
  ],
  status_options: [
    {
      value: 'active',
      name: 'Active',
    },
    {
      value: 'inactive',
      name: 'Inactive',
    },
  ],
  type_options: [
    {
      value: 'once',
      name: 'Once',
    },
    {
      value: 'weekly',
      name: 'Weekly',
    },
    {
      value: 'monthly',
      name: 'Monthly',
    },
    {
      value: 'automated',
      name: 'Automated',
    },
  ],

  weekdays_options: [
    {
      value: 'monday',
      name: 'Monday',
    },
    {
      value: 'tuesday',
      name: 'Tuesday',
    },
    {
      value: 'wednesday',
      name: 'Wednesday',
    },
    {
      value: 'thursday',
      name: 'Thursday',
    },
    {
      value: 'friday',
      name: 'Friday',
    },
    {
      value: 'saturday',
      name: 'Saturday',
    },
    {
      value: 'sunday',
      name: 'Sunday',
    },
  ],

  monthdays_options: [
    {
      value: '1',
      name: '1st',
    },
    {
      value: '2',
      name: '2nd',
    },
    {
      value: '3',
      name: '3rd',
    },
    {
      value: '4',
      name: '4th',
    },
    {
      value: '5',
      name: '5th',
    },
    {
      value: '6',
      name: '6th',
    },
    {
      value: '7',
      name: '7th',
    },
    {
      value: '8',
      name: '8th',
    },
    {
      value: '9',
      name: '9th',
    },
    {
      value: '10',
      name: '10th',
    },
    {
      value: '11',
      name: '11th',
    },
    {
      value: '12',
      name: '12th',
    },
    {
      value: '13',
      name: '13th',
    },
    {
      value: '14',
      name: '14th',
    },
    {
      value: '15',
      name: '15th',
    },
    {
      value: '16',
      name: '16th',
    },
    {
      value: '17',
      name: '17th',
    },
    {
      value: '18',
      name: '18th',
    },
    {
      value: '19',
      name: '19th',
    },
    {
      value: '20',
      name: '20th',
    },
    {
      value: '21',
      name: '21st',
    },
    {
      value: '22',
      name: '22nd',
    },
    {
      value: '23',
      name: '23rd',
    },
    {
      value: '24',
      name: '24th',
    },
    {
      value: '25',
      name: '25th',
    },
    {
      value: '26',
      name: '26th',
    },
    {
      value: '27',
      name: '27th',
    },
    {
      value: '28',
      name: '28th',
    },
    {
      value: '29',
      name: '29th',
    },
    {
      value: '30',
      name: '30th',
    },
    {
      value: '31',
      name: '31st',
    },
    {
      value: 'nth-1',
      name: 'Last day of the month',
    },
  ],
}
