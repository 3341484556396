








































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'

@Component({})
export default class SpinbuttonInput extends Vue {
  @Prop({ default: '' })
  public value!: string

  @Prop({ default: 'text' })
  public label_format!: string

  @Prop({ default: true })
  public edit!: boolean

  @Prop({ default: '' })
  public name!: string

  @Prop({ default: '' })
  public label!: string

  @Prop({ default: '' })
  public description!: string

  @Prop({ default: 1 })
  public step!: number

  @Prop({ default: '' })
  public vid!: string

  @Prop({ default: 'input' })
  public id!: string

  @Prop({ default: 'text' })
  public type!: string

  @Prop({ default: 0 })
  public min!: number

  @Prop({ default: 100 })
  public max!: number

  public local_value: string = ''

  public update: boolean = true

  @Prop({ default: '' })
  public rules!: [Object, String]

  public secrect: string = ''

  public get uuid() {
    return `${this.id}-${this.secrect}`
  }

  @Watch('local_value')
  public onChangeValue() {
    if (!this.edit) return
    this.$emit('input', this.local_value)
    this.update = false
    setTimeout(() => {
      this.update = true
    }, 200)
  }

  @Watch('value')
  public onChangeProp(val: any, old: any) {
    if (this.update) {
      this.local_value = this.value
    }
    this.update = true
  }

  public mounted() {
    this.secrect = String(Math.floor(Math.random() * Math.floor(999)))
  }

  public created() {
    this.local_value = this.value
  }
}
